import cx from "classnames";
import { PropsWithChildren } from "react";

type PriceDropBadgeProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export const PriceDropBadge = ({
  className,
  children,
  ...props
}: PropsWithChildren<PriceDropBadgeProps>) => (
  <div
    className={cx(
      "absolute w-max flex items-center gap-1 -top-3 z-20 text-sm rounded-full text-white font-semibold lg:text-base",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
