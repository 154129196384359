import cx from "classnames";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { formatToCurrency, priceAfterDiscount } from "utils/price.utils";
import { PriceAdvantage } from "utils/usePriceAdvantage";
import { useI18n } from "utils/with-i18n.utils";

type UpfrontLabelProps = {
  upfrontPrice: number;
  priceAdvantage: PriceAdvantage;
  isBlackWeekProduct: boolean;
};

export const UpfrontLabel = ({
  upfrontPrice,
  priceAdvantage: {
    hasUpfrontPriceDrop,
    hasAdvantage,
    formatedOriginalPriceUpfront,
    discountPercentage,
    currentSeniorityPlan,
    hasSeniorityDiscount,
  },
  isBlackWeekProduct = false,
}: UpfrontLabelProps) => {
  const i18n = useI18n();
  const { isBlackFriday } = useSettings();

  const isBlackWeekTheme = isBlackFriday && isBlackWeekProduct;

  const upfrontPriceLabel =
    hasUpfrontPriceDrop || currentSeniorityPlan
      ? i18n.t(
          "catalog.label.upfrontDiscount",
          {
            originalPrice: formatedOriginalPriceUpfront,
            price: formatToCurrency(
              "fr-FR",
              "EUR",
              priceAfterDiscount(upfrontPrice ?? 0, discountPercentage ?? 0),
              undefined,
              true,
            ),
            originalPriceClassName: isBlackFriday
              ? "text-mountbatten-pink"
              : currentSeniorityPlan
              ? "text-white text-opacity-50"
              : "text-gray",
            priceClassName: currentSeniorityPlan
              ? "font-bold text-white"
              : isBlackWeekTheme
              ? "font-bold text-transparent bg-clip-text bg-white"
              : "font-bold text-pink",
          },
          { html: true },
        )
      : i18n.t(
          "catalog.label.upfront",
          {
            price: formatedOriginalPriceUpfront,
          },
          { html: true },
        );

  return (
    <div
      className={cx(
        "flex flex-col items-center text-sm lg:text-lg",
        { "py-3 lg:mt-2": !hasUpfrontPriceDrop },
        {
          "w-full text-center text-white rounded-b-lg border-t border-black py-3 mt-2 lg:mt-5 bg-gradient-to-r from-neon-blue to-sky-magenta":
            hasSeniorityDiscount,
        },
        {
          "w-full text-center rounded-b-lg border-t border-black py-3 mt-2 lg:mt-2 bg-pink bg-opacity-20":
            !hasSeniorityDiscount && hasUpfrontPriceDrop && !isBlackWeekTheme,
        },
        {
          "w-full text-center text-white rounded-b-lg border-t border-black py-3 mt-2 lg:mt-5 bg-christmas text-xs lg:text-lg":
            !hasSeniorityDiscount && hasUpfrontPriceDrop && isBlackWeekTheme,
        },
      )}
    >
      <p>{upfrontPriceLabel}</p>
    </div>
  );
};
